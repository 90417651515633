import React, { useEffect, useState, useRef, useContext } from "react";
import { ListItem, ListItemIcon, ListItemText, styled } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import {
  Grid,
  Box,
  Typography,
  ThemeProvider,
  TextField,
  FormLabel,
  Stack,
  LinearProgress,
  CircularProgress,
  Divider,
  IconButton,
  List,
  Alert,
  Modal,
  Drawer,
  Chip,
  Avatar,
} from "@mui/material";
// import { useMediaQuery } from "@mui/material";
import { useMediaQuery } from "@mui/material";
// import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme, createFilterOptions, Autocomplete } from "@mui/material";
// import { createTheme } from "@mui/material/styles";
import {
  KeyboardArrowRight,
  RocketLaunch,
  Whatshot,
} from "@mui/icons-material";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import {
  useForm,
  FormProvider,
  Controller,
  useFormContext,
} from "react-hook-form";
import { useFeatureFlag } from "@harnessio/ff-react-client-sdk";
import axios from "axios";
import CloseIcon from "@mui/icons-material/Close";
import _ from "lodash";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import toast, { Toaster, ToastBar } from "react-hot-toast";
import PopularityChart from "./PopularityChartV2";
import IndustrialUsageChart from "./IndustrialUsageChartV2";
import PotentialSalaryIncraeseChart from "./PotentialSalaryIncraeseChart";
// import LoadingButton from "@mui/lab/LoadingButton";
// import Tab from "@mui/material/Tab";
// import TabContext from "@mui/lab/TabContext";
// import TabList from "@mui/lab/TabList";
// import TabPanel from "@mui/lab/TabPanel";
import DeviceInfoAndLocation from "./global/DeviceInfoAndLocation";
import SpeedDial from "@mui/material/SpeedDial";
import ShareIcon from "@mui/icons-material/Share";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import FileCopyIcon from "@mui/icons-material/FileCopyOutlined";
import { RWebShare } from "react-web-share";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { gsap, ScrollTrigger, Draggable, MotionPathPlugin } from "gsap/all";
import InfoIcon from "@mui/icons-material/Info";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import HelpIcon from "@mui/icons-material/Help";
import Joyride from "react-joyride";
import { OAuthCtx } from "./auth/AuthContext";
import { Auth, Amplify } from "aws-amplify";
import { setupAuthInterceptor } from "./utils/apis";
import SignInModal from "./auth/components/SignIn";
import { getEmail, getUsername, setUserEmail } from "./utils/storage";
import LogoutIcon from "@mui/icons-material/Logout";
import LoginIcon from "@mui/icons-material/Login";
import MenuIcon from "@mui/icons-material/Menu";
import apis from "./utils/apis";
import {
  checkYearWithGpt,
  GetBackGroundColor,
  Legend,
} from "./utils/GlobalFunctions";
import { motion } from "framer-motion";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import GoogleTrendsEmbed from "./Components/GoogleTrends";
import FooterComponent from "./Components/Footer";

// Amplify.configure({
//   Auth: {
//     region: process.env.REACT_APP_REGION,
//     userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
//     userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
//     // oauth: {
//     //   domain: process.env.REACT_APP_AWS_DOMAIN,
//     //   redirectSignIn: getRedirectSignInURL(),
//     //   redirectSignOut: getRedirectSignOutURL(),
//     //   responseType: "code",
//     // },
//   },
// });

gsap.registerPlugin(ScrollTrigger);

function getFirstWord(inputString) {
  const words = inputString.split(" ");
  if (words.length > 0) {
    return words[0];
  } else {
    return ""; // Return an empty string if there are no words in the input
  }
}

function ScrollDown() {
  window.scrollTo({
    top: document.documentElement.scrollHeight,
    behavior: "smooth",
  });
}

function TechRelevanceV4() {
  const theme = useTheme();
  const { setAuthState } = useContext(OAuthCtx);
  const { getAuthState } = useContext(OAuthCtx);

  const {
    control,
    handleSubmit,
    setError,
    getValues,
    setValue,
    formState: { errors },
  } = useForm();

  const relevenceTheme = createTheme({
    typography: {
      // fontFamily: [" Montserrat", "sans-serif"].join(","),
      fontFamily: `"Montserrat", 'normal'`,
    },
    palette: {
      primary: {
        main: "rgba(108,82,229,1)",
      },
      // secondary: {
      //   main: green[500],
      // },
    },
  });
  const [openTooltip, setOpenToolTip] = React.useState({});
  const methods = useForm({ criteriaMode: "all" });
  const [UserName, setUserName] = useState("");
  const [loadingButton, setLoadingButton] = React.useState(false);
  const [relevanceResult, setRelevanceResult] = useState("");
  const [formdata, setFormdata] = useState("");
  const [chatGptButton, setChatGptButton] = useState(false);
  const [checkSkill, setCheckSkill] = useState("");
  const [skillList, setSkillList] = useState();
  const [interviewQuestions, setInterviewQuestions] = useState();
  let initialCount = 1;
  const [chatGPTData, setChatGPTData] = useState("");
  const [chatgptError, setChatgptError] = useState(false);
  const staticRandomEncodeDecodeString = useFeatureFlag(
    "staticRandomEncodeDecodeString"
  );
  const secretEncodeDecodeString = useFeatureFlag("secretEncodeDecodeString");
  const poweredByChatGPT = useFeatureFlag("Chat_GPT_Boost");
  const pageTitle =
    "Professionals Tech Innovation Software India | TrueSelfy Tech Relevance";
  const pageDescription =
    "Unlock the Future with TrueSelfy's Tech Innovation in India! Explore Top Tech Software for Professionals. Dive into Tech Relevance Today!";
  const CalculateButtonValue = useFeatureFlag("Calculate_Button_text");
  const textFieldRef = useRef(null);
  const [getInterviewQuestions, setGetInterviewQuestions] = useState(false);
  const apiTimeout = { timeout: 60000 };
  // const [value, setValue] = React.useState("figma");
  const [location, setLocation] = useState();
  const [deviceInfo, setDeviceInfo] = useState();
  const Refer_Link_Description_Write_up = useFeatureFlag(
    "Refer_Link_Description_Write_up"
  );
  const Hide_google_trends = useFeatureFlag("Hide_google_trends");
  const [shareLink, setShareLink] = useState(
    `${process.env.REACT_APP_BASE_URL}/signin`
  );
  const [targetElement, setTargetElement] = useState("relevance-result");
  const [relevanceTooltip, setRelevanceTooltip] = useState(false);
  const [run, setRun] = useState(false);
  const [insightsStep, setInsightsStep] = useState(false);
  const isMobile = useMediaQuery("(max-width:600px)");
  const [skillLength, setSkillLength] = useState(0);
  const [userExist, setUserExist] = useState(true);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  const [videoLoaded, setVideoLoaded] = useState(false);

  const handleVideoLoaded = () => {
    setVideoLoaded(true);
  };

  const fetchUserSession = async () => {
    try {
      const userAuthenticated = await getAuthState();

      if (userAuthenticated !== null) {
        setupAuthInterceptor(getAuthState);
        const userCreated = sessionStorage.getItem("userCreated");

        if (userCreated === null && isLoggedIn) {
          const payload = {
            email: getEmail(),
            registerTime: new Date(Date.now()).toUTCString(),
            lastLoginTime: new Date(Date.now()).toUTCString(),
            userOf: "relevance",
          };
          apis
            .POST({
              endpoint: "/create-user",
              payload: payload,
              options: { type: "json" },
            })
            .then((object) => {
              // console.log(object.data);
              setLoading(false);
              sessionStorage.setItem("userCreated", true);
            })
            .catch((error) => {
              sessionStorage.setItem("userCreated", true);
              console.log(error);
            });
        }
        // await setAuthState(getAuthState);
      } else {
        localStorage.clear();
        sessionStorage.clear();
      }
      return getAuthState;
    } catch (error) {
      console.error("Error fetching user session:", error);
    }
  };

  function getUserStatus() {
    const login = sessionStorage.getItem("isLoggedIn");
    if (login === "true") {
      return true;
    } else {
      return false;
    }
  }

  const isLoggedIn = getUserStatus();

  function randomIntFromInterval(min, max) {
    // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  const PotentialSalaryIncraese = [
    "10",
    "20",
    "30",
    "15",
    "20",
    "30",
    "15",
    "30",
  ];

  const easeOfLearning = ["Easy", "Medium", "Hard"];
  //  check user logged or not

  useEffect(() => {
    fetchUserSession();
  }, [isLoggedIn]);

  useEffect(() => {
    getUserInfo();
    if (isLoggedIn) {
      setLoading(false);
      const element = document?.getElementById("enter-your-skills");
      element?.scrollIntoView();
    }
    const userType = sessionStorage.getItem("userType");
    if (userType === "user-auth") {
      const element = document?.getElementById("enter-your-skills");
      element?.scrollIntoView();
    }

    if (!isLoggedIn) {
      setRelevanceResult("");
    }

    const pageLoading = setTimeout(() => {
      setLoading(false);
    }, 2000);
    // return () => clearTimeout(pageLoading);
  }, []);

  useEffect(() => {
    const element = document?.getElementById("enter-your-skills");
    element?.scrollIntoView();
  }, [isLoggedIn]);

  const getUserInfo = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const idTokenPayload = user.signInUserSession.idToken.payload;

      // Extract user information from idTokenPayload
      const email = idTokenPayload.email;
      setUserEmail(email);
      const name =
        idTokenPayload.name ||
        `${idTokenPayload.given_name || ""} ${
          idTokenPayload.family_name || ""
        }`.trim();

      // console.log("Email:", email);
      // console.log("User name:", name || "No name available");
    } catch (error) {
      console.error("Error getting user info:", error);
    }
  };

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  const introSteps = [
    {
      target: ".technical-validity-box",
      content:
        "💼 Unleash your potential! Our 'Technical Validity' feature validates the authenticity and proficiency of your top skill ✅, helping you build trust and credibility in your professional network.",
    },
    {
      target: ".skill-score",
      content:
        "Meet our Skill Score feature! It's like a crystal ball 🔮 for your skills, showing you their demand status in the industry with estimate year and how widely they're adopted by different companies. It's your guide to navigating the job market with confidence!",
    },
    {
      target: ".get-insights",
      content:
        "Ready for more? Hit 'Get Insights' for enterprise adoption 🏢, skill popularity 🎓, and potential salary boosts 💰. Let's uncover your path to success! 🌟",
    },
  ];

  const InsightsSteps = [
    {
      target: ".skill-popularity",
      content:
        "📊 Skill popularity with ease! Our module rates skills on a scale of 1 to 10, showcasing their popularity among learners 🎓",
    },
    {
      target: ".enterprise-adoption",
      content:
        "Discover 🏢 industry demand at a glance! Our Enterprise Adoption module rates skills on a scale of 0 to 100%, giving you a clear picture of their acceptance among businesses",
    },
    {
      target: ".lear-earn-recommendations",
      content: `Unlock 📚 learning opportunities and potential earnings! Our Learn & Earn Recommendations feature suggests how many skills you can acquire alongside your chosen skill. Click '${
        isMobile ? ":" : "learn more"
      }' to uncover these valuable suggestions and chart your path to success! 🌟`,
    },
  ];

  const staticQuestions = {
    java: [
      {
        question:
          "Explain the difference between abstract classes and interfaces in Java.",
        answer:
          "Abstract classes and interfaces are both used to achieve abstraction in Java. An abstract class can have both abstract (unimplemented) and concrete (implemented) methods, while an interface can only have abstract methods. A class can extend only one abstract class, but it can implement multiple interfaces. Abstract classes can have constructors, whereas interfaces cannot. Use abstract classes when you want to share code among closely related classes, and use interfaces when you want to enforce a contract for unrelated classes.",
      },
      {
        question:
          "What is the significance of the 'static' keyword in Java? Provide examples.",
        answer:
          "In Java, the 'static' keyword is used to declare members (fields or methods) that belong to the class rather than instances of the class. Static members are shared among all instances of the class. For example, a static method can be called using the class name (e.g., ClassName.method()), and a static variable is shared among all instances. Static methods are commonly used for utility methods or factory methods that don't depend on the state of an instance.",
      },
      {
        question:
          "Explain the concept of exception handling in Java. How does it help in writing robust code?",
        answer:
          "Exception handling in Java is a mechanism to handle runtime errors, ensuring the smooth execution of a program. It involves the use of 'try,' 'catch,' 'finally,' and 'throw' keywords. Code that might throw an exception is placed within a 'try' block, and if an exception occurs, it is caught and handled in the 'catch' block. The 'finally' block is used to execute code that must be run, whether an exception is thrown or not. Proper exception handling improves code robustness by preventing unexpected failures and providing a mechanism to gracefully handle errors.",
      },
    ],
    reactjs: [
      {
        question:
          "Explain the component lifecycle methods in React.js. Provide examples of when each method is called.",
        answer:
          "React components go through a lifecycle of events, and each event triggers a set of methods. Some key lifecycle methods include 'componentDidMount,' 'componentDidUpdate,' and 'componentWillUnmount.' 'componentDidMount' is called after the component is rendered for the first time. 'componentDidUpdate' is called after a component's state or props are updated. 'componentWillUnmount' is called before a component is removed from the DOM. These methods allow developers to perform actions at different stages of a component's existence, such as fetching data after the component mounts or cleaning up resources before the component is unmounted.",
      },
      {
        question:
          "What is the virtual DOM in React.js? How does it contribute to the performance of React applications?",
        answer:
          "The virtual DOM is a lightweight copy of the actual DOM in a React application. When the state of a React component changes, a new virtual DOM is created, and the difference (diffing) between the new and old virtual DOMs is calculated. Only the necessary changes are then applied to the actual DOM, resulting in more efficient updates. This minimizes the direct manipulation of the DOM, reducing the performance cost. The virtual DOM allows React to update the UI efficiently, providing a smoother and faster user experience.",
      },
      {
        question:
          "Explain the purpose of React Hooks. Provide examples of commonly used hooks.",
        answer:
          "React Hooks are functions that enable functional components to manage state and side effects. They were introduced in React 16.8 to allow the use of state and other React features without writing a class. Some commonly used hooks include 'useState' for managing state in functional components, 'useEffect' for handling side effects in a component (e.g., data fetching), and 'useContext' for accessing context in a functional component. Hooks provide a cleaner and more concise way to manage component logic, making functional components as powerful as class components.",
      },
    ],
    nodejs: [
      {
        question:
          "Explain the event loop in Node.js and how it differs from traditional multi-threading.",
        answer:
          "Node.js uses a single-threaded event loop to handle asynchronous operations. The event loop continuously checks the message queue for new events and processes them one at a time. This is different from traditional multi-threading, where each thread runs independently. In Node.js, the non-blocking I/O operations allow the single thread to handle many connections simultaneously, making it efficient for handling a large number of concurrent requests.",
      },
      {
        question:
          "Describe the concept of middleware in Express.js (Node.js). Provide an example of using middleware in an Express application.",
        answer:
          "Middleware in Express.js are functions that have access to the request, response, and the next middleware function in the application's request-response cycle. They can modify the request or response objects, end the request-response cycle, or call the next middleware in the stack. An example of using middleware is adding authentication to routes. For instance, you can create a middleware function to check if a user is authenticated and apply it to specific routes using app.use(authMiddleware).",
      },
      {
        question: "What is npm? How does it contribute to Node.js development?",
        answer:
          "npm (Node Package Manager) is the default package manager for Node.js, allowing developers to install, manage, and share packages or libraries. It simplifies dependency management in Node.js projects by providing a centralized repository of reusable code modules. Developers can use npm to install third-party packages, manage project dependencies, and publish their own packages. npm also facilitates version management, making it easy to specify and install specific versions of packages. It plays a crucial role in building scalable and maintainable Node.js applications by promoting code reuse and collaboration.",
      },
    ],
  };

  function checkSkillType(year) {
    const currentYear = new Date().getFullYear();
    const minYearDiff = 5;
    const minYearForCurrentSkill = 1;
    const minYearForOutdated = 4;
    if (year > currentYear) {
      return `Skill trending and in demand until ${year}`;
    } else if (year >= currentYear - minYearForCurrentSkill) {
      return `Skill current and in demand until ${year}`;
    } else if (year >= currentYear - minYearForOutdated) {
      return `Skill getting outdated fast ${year}`;
    } else if (year <= currentYear - minYearDiff) {
      return `Skill obselete ${year}`;
    }
  }
  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_API_BASE_URL + `/get-relevanceSkill?skills=java`,
        {}
      )
      .then((object) => {});
  }, []);

  useEffect((item) => {
    // setTimeout(() => {
    //   setRun(true);
    // }, 3000);
    DeviceInfoAndLocation(setLocation, setDeviceInfo);
  }, []);

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(shareLink);
    toast.success("Copied");
  };
  // const openai = new OpenAI({
  //   apiKey: "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", // defaults to process.env["OPENAI_API_KEY"]
  //   dangerouslyAllowBrowser: true,
  // });

  const actions = [
    { icon: <FileCopyIcon onClick={handleCopyToClipboard} />, name: "Copy" },
    {
      icon: (
        <RWebShare
          data={{
            text: Refer_Link_Description_Write_up,
            url: shareLink,
            title:
              "Future Pay Scale Prediction Calculator Tool Online | Endorse My Expertise",
          }}
          onClick={() => console.log("shared successfully!")}
        >
          <ShareIcon />
        </RWebShare>
      ),
      name: "Share",
    },
  ];

  const messages = [
    "Hello, how can I assist you?",
    "I can provide information and answer questions.",
    "Just type your query, and I will do my best to help!",
  ];

  function checkYear(year) {
    const currentYear = new Date().getFullYear();
    const minYearDiff = 5;
    const minYearForOutdatedSkill = 4;
    const minYearForCurrentSkill = 1;

    if (year > currentYear) {
      const labelSuggestion = (
        <Typography
          sx={{
            fontFamily: "Montserrat",
            // fontSize: "20px",
            color: "#232323",

            textAlign: "center",
          }}
          // mb={{ xs: 2, md: 2, lg: 3, xl: 3 }}
          variant="h6"
        >
          <span
            style={{
              // fontSize: "20px",
              fontWeight: 700,
              color: "#26801E",
            }}
          >
            Trending{" "}
          </span>{" "}
          : You are in demand, but keep upgrading
        </Typography>
      );
      return {
        label: `Technical skill trending and in demand until ${year}`,
        barPrimaryColor: "#278C37",
        barSecondaryColor: "#9ad7a3",
        labelSuggestion,
        percentageColor: "#278C37",
      };
    } else if (year >= currentYear - minYearForCurrentSkill) {
      const labelSuggestion = (
        <Typography
          sx={{
            fontFamily: "Montserrat",
            fontSize: "20px",
            color: "#232323",
            mb: "47px",
            textAlign: "center",
          }}
        >
          <span
            style={{
              fontSize: "20px",
              fontWeight: 700,
              color: "darkorange",
            }}
          >
            • Current
          </span>{" "}
          <spna>: Your tech is current, but it is getting outdated fast</spna>
        </Typography>
      );
      return {
        label: `Technical skill in demand until ${year}`,
        barPrimaryColor: "#E9B112",
        barSecondaryColor: "#f5e7be",
        labelSuggestion,
        percentageColor: "#E9B112",
      };
    } else if (year >= currentYear - minYearForOutdatedSkill) {
      const labelSuggestion = (
        <Typography
          sx={{
            fontFamily: "Montserrat",
            fontSize: "20px",
            color: "#232323",
            mb: "47px",
            textAlign: "center",
          }}
        >
          <span
            style={{
              fontSize: "20px",
              fontWeight: 700,
              color: "#ffc107",
            }}
          >
            • Getting outdated
          </span>{" "}
          <span>: Upskill soon to stay ahead in the tech industry</span>
        </Typography>
      );
      return {
        label: `Technical skill getting outdated fast ${year}`,
        barPrimaryColor: "#C60015",
        barSecondaryColor: "#ffa7b0",
        labelSuggestion,
        percentageColor: "#C60015",
      };
    } else if (year <= currentYear - minYearDiff) {
      const labelSuggestion = (
        <Typography
          sx={{
            fontFamily: "Montserrat",
            fontSize: "24px",
            color: "#232323",
            mb: "47px",
            textAlign: "center",
          }}
        >
          <span
            style={{
              fontSize: "20px",
              fontWeight: 700,
              color: "red",
            }}
          >
            • Obsolete
          </span>{" "}
          : Please learn new age skills now
        </Typography>
      );
      return {
        label: `Technical skill obselete ${year}`,
        barPrimaryColor: "#028EDF",
        barSecondaryColor: "#b7e5ff",
        labelSuggestion,
        percentageColor: "#028EDF",
      };
    }
  }

  function getTopSkill(skillArray) {
    let maxExpiryYear = -Infinity;
    let maxExpiryYearObject = null;

    // Iterate through the array
    for (const obj of skillArray) {
      if (
        obj.expiryYear > maxExpiryYear &&
        obj.expiryYear !== null &&
        obj.expiryYear < new Date().getFullYear() + 25
      ) {
        maxExpiryYear = obj.expiryYear;
        maxExpiryYearObject = obj;
      } else {
        if (obj?.expiryYear > new Date().getFullYear() + 100) {
          maxExpiryYear = parseFloat(obj?.estYear) + 10;
          maxExpiryYearObject = {
            ...obj,
            expiryYear: parseFloat(obj?.estYear) + 15,
          };
        } else if (obj?.estYear === null || obj.estYear === undefined) {
          maxExpiryYear = new Date().getFullYear() + 2;
          maxExpiryYearObject = {
            ...obj,
            expiryYear: new Date().getFullYear() + 2,
          };
        }
      }
    }
    const topSkillSuggestion = checkYear(maxExpiryYearObject?.expiryYear);

    return {
      maxExpiryYear,
      labelSuggestion: topSkillSuggestion?.labelSuggestion,
      maxExpiryYearObject,
    };
  }

  // Data Encryption function
  function DataEncryption(json) {
    const jsonString = JSON.stringify(json);
    const customStringJson =
      secretEncodeDecodeString + jsonString + secretEncodeDecodeString;
    const newCustomStringJson = customStringJson.replace(
      /"/g,
      staticRandomEncodeDecodeString
    );
    const encodedString = btoa(newCustomStringJson);
    return encodedString;
  }
  function DataDecryption(jsonString) {
    const decodedString = atob(jsonString);
    const getOldStringOne = decodedString.replace(
      new RegExp(secretEncodeDecodeString, "g"),
      ""
    );
    const str = staticRandomEncodeDecodeString;
    const escapedString = str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    const regex = new RegExp(escapedString, "g");
    const getOldStringTwo = getOldStringOne.replace(regex, '"');
    const json = JSON.parse(getOldStringTwo);
    return json;
  }

  // state for handle logout button
  const [openLogout, setOpenLogout] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const isSmallDevice = useMediaQuery("(max-width: 960px)");

  const handleOpen = () => setOpenLogout(true);
  const handleClose = () => setOpenLogout(false);

  const handleLogout = () => {
    localStorage.clear();
    sessionStorage.clear();
    // Perform additional logout actions if needed
    handleClose();
  };
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const [showOTPForm, setShowOTPForm] = React.useState(false);
  const [userData, setUserData] = React.useState("");
  const shouldRunEffect = useRef(false);
  const cancelSkillToken = useRef(null);

  // tech relevance handler
  const onSubmit = async (data) => {
    // console.log(data, "data123");
    const userSession = await fetchUserSession();
    // console.log(
    //   DataDecryption(
    //     "VHJ1ZVNlbGZ5e3N6ZnJwbWJ1OW1lRFNAKiNEU3RvcFByaW1hcnlTa2lsbHN6ZnJwbWJ1OW1lRFNAKiNEUzpbe3N6ZnJwbWJ1OW1lRFNAKiNEU19pZHN6ZnJwbWJ1OW1lRFNAKiNEUzpzemZycG1idTltZURTQCojRFM2NDM5NTU3NGJiNDI2NDRmYjk4ZThjOTRzemZycG1idTltZURTQCojRFMsc3pmcnBtYnU5bWVEU0AqI0RTbmFtZXN6ZnJwbWJ1OW1lRFNAKiNEUzpzemZycG1idTltZURTQCojRFNPcmFjbGUgRVJQIEltcGxlbWVudGF0aW9uc3N6ZnJwbWJ1OW1lRFNAKiNEUyxzemZycG1idTltZURTQCojRFNlc3RZZWFyc3pmcnBtYnU5bWVEU0AqI0RTOnN6ZnJwbWJ1OW1lRFNAKiNEUzIwMjBzemZycG1idTltZURTQCojRFMsc3pmcnBtYnU5bWVEU0AqI0RTdHlwZXN6ZnJwbWJ1OW1lRFNAKiNEUzpzemZycG1idTltZURTQCojRFN5c3pmcnBtYnU5bWVEU0AqI0RTLHN6ZnJwbWJ1OW1lRFNAKiNEU2NoYXRfZ3B0c3pmcnBtYnU5bWVEU0AqI0RTOnRydWUsc3pmcnBtYnU5bWVEU0AqI0RTZXhwaXJ5WWVhcnN6ZnJwbWJ1OW1lRFNAKiNEUzoxMDEwMjYwMTA5LHN6ZnJwbWJ1OW1lRFNAKiNEU3Njb3Jlc3pmcnBtYnU5bWVEU0AqI0RTOjIwMjA1MTYyMzh9XSxzemZycG1idTltZURTQCojRFN1c2VyRXhpc3RzemZycG1idTltZURTQCojRFM6dHJ1ZX1UcnVlU2VsZnk="
    //   ),
    //   "skill"
    // );
    // console.log(userSession, "userSession !== undefined");
    if (userSession !== undefined || userSession !== null) {
      setUserExist(true);
      setLoadingButton(true);
      const userName = getUsername();
      setUserName(userName || "Your");
      setFormdata(data);
      setCheckSkill("");
      setGetInterviewQuestions(false);
      data.email = getEmail();
      data.userLocation = location;
      data.deviceInfo = deviceInfo;
      setTargetElement("relevance-result");
      setSkillLength(methods.getValues("primarySkill")?.length);
      // console.log("autoValue", autoValue);
      try {
        axios
          .post(
            process.env.REACT_APP_API_BASE_URL + "/get-relevanceResult",
            DataEncryption(data),
            {
              options: {
                type: "json",
              },
            }
          )
          .then((object) => {
            const data = DataDecryption(object.data);
            // console.log("Data of relevance", data);
            setRelevanceResult(data);
            setSkillList(data?.topPrimarySkill);
            // console.log(data, "data");
            setUserExist(data?.userExist);
            setLoadingButton(false);
            const element = document?.getElementById("relevance-result");
            element?.scrollIntoView();
            // if (data?.userExist === false) {
            const joyride = setTimeout(() => {
              setRun(true);
              setUserExist(data?.userExist);
            }, 1000);
            return () => clearTimeout(joyride);
            // } else {
            //   setRun(false);
            // }
            // methods.reset("");
            // setValue("skills", "");
          })
          .catch((error) => {
            if (
              error?.response?.status === 500 &&
              error?.response?.data?.message === "Email verification failed"
            ) {
              methods.setError("email", {
                type: "validation",
                message: "Email not valid !!",
              });
              setRelevanceResult("");
            }

            setLoadingButton(false);
          });
      } catch (error) {
        // console.log(error);
        setLoadingButton(false);
      }
    }
  };

  // chatgpt response handler
  const handleSendClick = async (name) => {
    setChatgptError(false);
    setCheckSkill(name);
    setGetInterviewQuestions(false);
    setTargetElement("chat-gpt-result");
    if (userExist) {
      setInsightsStep(false);
    }
    const skill = getTopSkill(relevanceResult?.topPrimarySkill);
    try {
      setChatGptButton(true);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "/get-chatgpt-response",
          DataEncryption({ skillName: name }),
          {
            options: {
              type: "json",
            },
          }
        )
        .then((object) => {
          setChatGptButton(false);
          const data = DataDecryption(object.data);
          let gptdata = JSON.parse(data?.result);
          // console.log("gptdata", gptdata);
          // ScrollDown();
          if (Object.keys(gptdata).length < 3) {
            setChatGPTData(Object.values(gptdata)[0]);
            // if (userExist === false) {
            // console.log(userExist, "userExist");
            const joyRideChatGpt = setTimeout(() => {
              setInsightsStep(true);
            }, 1000);
            return () => clearTimeout(joyRideChatGpt);
            // }
          } else {
            if (
              gptdata.length === 0 ||
              gptdata === null ||
              gptdata === undefined ||
              Object.keys(gptdata).length === 0
            ) {
              setChatgptError(true);
              setChatGPTData("");
              // console.log(gptdata, "gptdata");
              toast.error("Something went wrong try again");
            } else {
              // setChatGPTData(gptdata);
              setChatGPTData(gptdata);
              // if (userExist === false) {
              // console.log(userExist, "userExist1");

              const joyRideChatGpt = setTimeout(() => {
                setInsightsStep(true);
              }, 1000);
              return () => clearTimeout(joyRideChatGpt);
              // } else {
              // setInsightsStep(false);
              // }

              // console.log(gptdata, "gptdata");
            }
          }

          // console.log(gptdata, "gptdata1");
        })
        .catch((error) => {
          setChatgptError(true);
          setChatGptButton(false);
          toast.error("Something went wrong try again");
        });
    } catch (error) {
      setChatgptError(true);
      setChatGptButton(false);
      toast.error("Something went wrong try again");
    }
  };

  // get interview questions
  const handleGetInterviewQuestions = () => {
    setGetInterviewQuestions(true);
    ScrollDown();
    // console.log({ primarySkill: skillList }, "payload");

    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "/get-interviewQuestion",
        DataEncryption({ primarySkill: skillList }),
        {
          headers: {
            "Content-Type": {
              json: "application/json",
              form: "application/x-www-form-urlencoded",
            },
            "Cache-Control": "no-cache",
            Pragma: "no-cache",
            Expires: "0",
          },
          ...apiTimeout,
        }
      )
      .then((object) => {
        const data = DataDecryption(object.data);
        setInterviewQuestions(DataDecryption(object.data));
        // console.log(DataDecryption(object.data), "interview questions");
      })
      .catch((error) => {
        toast.error("Something went wrong, try again");
      });
  };

  const RetryGPT = (name) => {
    if (initialCount <= 3) {
      initialCount += 1;
      handleSendClick(name);
    } else {
      return;
    }
  };

  // scroll down hook
  useEffect(() => {
    const element = document?.getElementById(targetElement);
    element?.scrollIntoView();
    // window.scrollTo({
    //   top: document.documentElement.scrollHeight,
    //   behavior: "smooth",
    // });
  }, [chatGptButton, relevanceResult]);
  const [topSkillValidity, setTopSkillValidity] = useState([]);
  // const [techRelevanceBtn, setTechRelevanceBtn] = useState(false);
  const [skillLoading, setSkillLoading] = useState(false);
  const [duplicateError, setDuplicateError] = useState(false);
  const [autoValue, setAutoValue] = useState(getValues("primarySkill") || []); // if dropdown open?

  // Tech Relevance form
  function TechRelevanceForm() {
    const getIconBySkillName = (skillName) => {
      const svgIconPath = `../../../assets/images/icons/${skillName}.svg`;
      const pngIconPath = `../../../assets/images/icons/${skillName}.png`;
      const darkIconPath = `../../../assets/images/dark-icons/${skillName}.png`;

      // Check if the SVG icon exists
      const svgExists = imageExists(svgIconPath);
      if (svgExists) {
        return svgIconPath;
      }
      // Check if the PNG icon exists
      const pngExists = imageExists(pngIconPath);
      if (pngExists) {
        return pngIconPath;
      }
      // Check if the dark SVG icon exists
      const darkExists = imageExists(darkIconPath);
      if (darkExists) {
        return darkIconPath;
      }

      // Return null if neither SVG nor PNG exists
      return null;
    };

    // Helper function to check if an image exists
    const imageExists = (url) => {
      const img = new Image();
      img.src = url;
      return img.complete && img.naturalHeight !== 0;
    };

    const {
      control,
      setValue,
      getValues,
      formState: { errors },
    } = useFormContext();

    const [open, setOpen] = useState(false); // if dropdown open?
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [noOptions, setNoOptions] = useState(false);

    const filter = createFilterOptions({
      stringify: (option) => option.name,
    });

    const [duplicateMessage, setDuplicateMessage] = useState(
      "Duplicate skill found and removed"
    );
    const compareArrays = (a, b) => {
      return JSON.stringify(a) === JSON.stringify(b);
    };

    useEffect(() => {
      // setValue("primarySkill", autoValue);
      if (methods.getValues("primarySkill")?.length !== skillLength) {
        setRelevanceResult("");
      }
      if (!shouldRunEffect.current) {
        shouldRunEffect.current = true;
        return;
      }

      if (
        autoValue.length != 0 &&
        !compareArrays(autoValue, topSkillValidity)
      ) {
        // console.log("component called");
        // console.log("getValues", getValues("primarySkill"));
        if (cancelSkillToken.current) {
          // console.log("cancelSkillToken", cancelSkillToken.current);
          setSkillLoading(true);

          // cancelSkillToken.current.cancel("Previous request cancelled");
        }

        try {
          cancelSkillToken.current = axios.CancelToken.source();
          setSkillLoading(true);
          setLoading(true);
          // setDuplicateError(false);
          setValue("primarySkill", autoValue);
          // setTechRelevanceBtn(true);
          axios
            .post(
              process.env.REACT_APP_API_BASE_URL + "/get-topSkillValidity",
              DataEncryption({
                primarySkill: autoValue,
              }),
              {
                cancelToken: cancelSkillToken.current.token,
                options: {
                  type: "json",
                },
              }
            )
            .then((object) => {
              const data = DataDecryption(object.data);
              // console.log(data, "Data skill relevance");
              // Find the index of the last duplicate skill
              let lastDuplicateIndex = -1;
              data.topPrimarySkill.forEach((skill, index) => {
                if (skill.isDuplicate) {
                  lastDuplicateIndex = index;
                }
              });

              if (lastDuplicateIndex !== -1) {
                setDuplicateError(true);
                shouldRunEffect.current = false;
                data.topPrimarySkill.pop();
                setAutoValue(data.topPrimarySkill);
                setTopSkillValidity(data.topPrimarySkill);
                setValue("primarySkill", data.topPrimarySkill);
                setSkillLoading(false);
              } else {
                shouldRunEffect.current = false;
                setSkillLoading(false);
                setAutoValue(data.topPrimarySkill);
                setTopSkillValidity(data.topPrimarySkill);
                setValue("primarySkill", data.topPrimarySkill);
              }
              setLoading(false);

              const AlertDuplicate = setTimeout(() => {
                setDuplicateError(false);
              }, 3000);
              return () => clearTimeout(AlertDuplicate);
              console.log(data, "Data relevance");
            });
        } catch (error) {
          setLoading(false);
          setSkillLoading(false);
          // setTechRelevanceBtn(false);
        }
      }
    }, [autoValue]);

    // useEffect(() => {
    //   setError("email", {
    //     type: "validation",
    //     message: "Email not valid !!",
    //   });
    // }, [setEmailError]);

    return (
      <React.Fragment>
        <Grid container justifyContent="center" spacing={{ xs: 2, md: 3 }}>
          <Grid item xs={12} md={6}>
            {/* <Box
              sx={{
                // maxWidth: "329px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            > */}
            <FormLabel
              sx={{
                fontFamily: "poppins",
                fontSize: "24px",
                fontWeight: 700,
                lineHeight: "42px",
                color: "#262655",
                paddingLeft: "14px",
              }}
            >
              Skills
            </FormLabel>

            <Legend />

            <Controller
              name={"primarySkill"}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Autocomplete
                  noOptionsText={
                    noOptions
                      ? "No options found"
                      : "Start typing to get options"
                  }
                  {...field}
                  open={open}
                  onOpen={() => {
                    setNoOptions(false);
                    setOpen(true);
                    setData([]);
                  }}
                  onClose={() => {
                    setOpen(false);
                  }}
                  loading={loading}
                  multiple
                  autoComplete={true}
                  isOptionEqualToValue={(option, value) =>
                    option.name === value.name
                  }
                  value={autoValue}
                  onChange={(event, newValue) => {
                    if (
                      newValue.slice(-1)[0] &&
                      newValue.slice(-1)[0].inputValue != undefined
                    ) {
                      setAutoValue([
                        ...autoValue,
                        {
                          name: newValue.slice(-1)[0].inputValue,
                          other: true,
                        },
                      ]);
                      // setTopSkillValidity([]);
                    } else {
                      setAutoValue(newValue);
                      // setTopSkillValidity([]);
                    }
                    setData([]);
                  }}
                  filterSelectedOptions={true}
                  onInputChange={(event, value) => {
                    setNoOptions(true);
                    // console.log("input changed");
                    // setLoading(true);
                    const trimmedValue = value?.trim();
                    if (trimmedValue) {
                      const encodedSkillName = Array.from(trimmedValue)
                        .map((char) => {
                          const isSpecialSymbol = /[!@#$%^&*(),.?":{}|<>]/.test(
                            char
                          );
                          return isSpecialSymbol
                            ? encodeURIComponent(char)
                            : char;
                        })
                        .join("");
                      const endpoint = `/get-relevanceSkill?skills=${encodedSkillName}`;
                      // console.log("data", data);
                      getSkillOptions(setData, endpoint, setLoading);
                    } else {
                      setLoading(false);
                    }
                  }}
                  id="free-solo-dialog-demo"
                  options={data}
                  getOptionLabel={(option) => {
                    // e.g value selected with enter, right from the input
                    if (typeof option === "string") {
                      return option;
                    }
                    if (option.inputValue) {
                      return option.inputValue;
                    }
                    return option.name;
                  }}
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  filterOptions={(options, params) => {
                    params.inputValue = params.inputValue.trim();
                    const filtered = filter(options, params);
                    return filtered;
                  }}
                  renderOption={(props, option, { inputValue }) => {
                    const matches = match(option.name, inputValue.trim());
                    const parts = parse(option.name, matches);

                    return (
                      <li {...props} key={option.name}>
                        <div>
                          {parts.map((part, index) => (
                            <span
                              key={index}
                              style={{
                                color: part.highlight ? "red" : "inherit",
                                fontWeight: part.highlight ? 700 : 400,
                              }}
                            >
                              {part.text}
                            </span>
                          ))}
                        </div>
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{
                        "& .MuiInputBase-root": {
                          borderRadius: { xs: "22px", lg: "22px !important" },
                        },
                      }}
                      inputRef={textFieldRef}
                      // autoFocus={question?.autoFocus}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                      error={errors?.primarySkill ? true : false}
                      helperText={
                        <>{errors?.primarySkill && "Skills cannot be empty"}</>
                      }
                      label={"Select multiple options"}
                    />
                  )}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => {
                      const yearInfo = checkYearWithGpt(option);
                      // console.log(option, "option?.type");
                      const iconUrl = getIconBySkillName(option.name);
                      // Determine whether to use fixed size or normal size
                      const isFixedSize = value.length <= 10;

                      return (
                        <Chip
                          {...getTagProps({ index })}
                          key={option?._id}
                          label={option?.name}
                          sx={{
                            backgroundColor:
                              yearInfo?.barSecondaryColor ||
                              "rgba(0, 0, 0, 0.08)",
                            color:
                              yearInfo?.label === "Default"
                                ? "#000000"
                                : "#ffffff",
                            border:
                              `1px solid ${yearInfo?.color}` ||
                              "rgba(0, 0, 0, 0.87)",
                            width: "auto",
                            height: isFixedSize ? "45px !important" : "auto", // Fixed height for 10 or fewer chips, auto otherwise
                            fontSize: isFixedSize ? "15px !important" : "13px", // Fixed font size for 10 or fewer chips, default otherwise
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                          avatar={
                            iconUrl ? (
                              <Avatar
                                src={iconUrl}
                                alt="Icon"
                                slotProps={{
                                  img: {
                                    loading: "lazy",
                                  },
                                }}
                                sx={{
                                  width: isFixedSize
                                    ? "25px !important"
                                    : "15px !important",
                                  height: isFixedSize
                                    ? "25px !important"
                                    : "15px !important",
                                  my: isFixedSize ? "0" : "3px",
                                  backgroundColor: "#ffffff",
                                  p: "5px",
                                }}
                              />
                            ) : (
                              <Avatar
                                alt="Icon"
                                slotProps={{
                                  img: {
                                    loading: "lazy",
                                  },
                                }}
                                sx={{
                                  fontSize: isFixedSize
                                    ? "13px !important"
                                    : "10px !important",
                                  width: isFixedSize
                                    ? "25px !important"
                                    : "15px !important",
                                  height: isFixedSize
                                    ? "25px !important"
                                    : "15px !important",
                                  my: isFixedSize ? "0" : "3px",
                                  backgroundColor: "#ffffff",
                                  p: "5px",
                                }}
                              >
                                {(() => {
                                  if (!option.name) return "";

                                  const words = option.name
                                    .split(" ")
                                    .filter((word) => word.length > 0);
                                  let abbreviation = "";

                                  const getFirstValidChar = (word) => {
                                    for (let i = 0; i < word.length; i++) {
                                      const char = word.charAt(i).toUpperCase();
                                      if (/^[a-zA-Z0-9]$/.test(char)) {
                                        return char;
                                      }
                                    }
                                    return "";
                                  };

                                  if (words.length === 1) {
                                    // Single word: Get the first valid character of the word
                                    abbreviation = getFirstValidChar(words[0]);
                                  } else if (words.length === 2) {
                                    // Two words: Combine the first valid character of each word
                                    abbreviation = words
                                      .map((word) => getFirstValidChar(word))
                                      .join("");
                                  } else if (words.length === 3) {
                                    // Three words: Combine the first valid character of each word
                                    abbreviation = words
                                      .map((word) => getFirstValidChar(word))
                                      .join("");
                                  } else if (words.length > 3) {
                                    // More than three words: Only use the first valid character of the first word
                                    abbreviation = getFirstValidChar(words[0]);
                                  }

                                  // Handle cases where abbreviation might still be empty (due to no valid characters)
                                  if (!abbreviation) {
                                    abbreviation = words
                                      .map((word) => getFirstValidChar(word))
                                      .join("");
                                  }

                                  return abbreviation;
                                })()}
                              </Avatar>
                            )
                          }
                        />
                      );
                    })
                  }
                  disabled={skillLoading}
                />
              )}
            />

            {duplicateError && (
              <Box sx={{ py: 0.5, mt: 1 }}>
                <Alert severity="warning">{duplicateMessage}</Alert>
              </Box>
            )}

            {/* </Box> */}
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }

  var cancelToken = null;
  function getSkillOptions(setData, endpoint, setLoading) {
    setData([]);
    setLoading(true);
    if (cancelToken) {
      // console.log("cancelToken", cancelToken);
      cancelToken.cancel("Previous request cancelled");
    }
    // console.log("");
    try {
      cancelToken = axios.CancelToken.source();
      axios
        .get(process.env.REACT_APP_API_BASE_URL + endpoint, {
          cancelToken: cancelToken.token,
        })
        .then((object) => {
          if (object?.status === 201) {
            var data = _.remove(DataDecryption(object.data), function (n) {
              return n.name !== "Other";
            });
            setData(data);
          } else {
            setData([]);
          }
          setLoading(false);
        })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            // Handle other errors here
            console.error("Error:", error);
          }
        });
    } catch (error) {
      // Handle any unexpected errors here
      console.error("Error:", error);
      setLoading(false);
    }
  }

  const handleTooltipClose = (key) => {
    // setOpenToolTip((prevState) => ({ ...prevState, [key]: false }));
    setOpenToolTip({});
  };

  const handleTooltipOpen = (key) => {
    if (openTooltip[key] === true) {
      setOpenToolTip((prevState) => ({ ...prevState, [key]: false }));
    } else {
      setOpenToolTip({});
      setOpenToolTip((prevState) => ({ ...prevState, [key]: true }));
    }
  };

  const StyledTooltip = styled(({ className, ...props }) => (
    <Tooltip
      {...props}
      componentsProps={{ tooltip: { className: className } }}
    />
  ))(`
      // border: 1px solid #008;
      // color: black;
      // background-color: #D6EFFF;
      // background-color: #FFF;
      // border-radius: 22px;
      // padding:"11px 34px !important";
      // filter: drop-shadow(0px 0px 12px rgba(0, 0, 136, 0.55));
  `);

  // custom tooltip component
  const CustomTooltipTitle = (data, index) => (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          alignItems: "start",
          justifyContent: "center",
        }}
      >
        {" "}
        <IconButton
          onClick={() => {
            handleTooltipClose(index);
          }}
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
          }}
        >
          <CloseIcon />
        </IconButton>
        <Box
          sx={{
            maxWidth: 164,
            minWidth: 120,
            width: "100%",
            flex: 1,
            padding: 0.5,
          }}
        >
          <Typography
            color="primary"
            textAlign="center"
            sx={{
              fontFamily: "poppins",
              fontSize: { xs: "14px", md: "16px" },
              fontWeight: 700,
              mb: "20px",
              textAlign: "center",
              padding: 0.5,
            }}
          >
            Ease of learning
          </Typography>
          <Box pt={{ xs: 3, sm: 0, md: 2, lg: 3, xl: 3 }}>
            <Typography
              color="primary"
              textAlign="center"
              sx={{
                fontFamily: "poppins",
                fontSize: { xs: "24px", md: "24px" },
                fontWeight: 700,
                textAlign: "center",
              }}
              pr={{ xs: 1, md: 0, lg: 0, xl: 0 }}
            >
              {data?.data?.easeOfLearning}
            </Typography>
          </Box>
        </Box>
        <Divider
          orientation="vertical"
          variant="middle"
          flexItem
          sx={{
            flex: 0.1,
            borderColor: "#008",
          }}
        />
        <Box sx={{ p: 0.5, flex: 1 }}>
          <Typography
            color="primary"
            textAlign="center"
            sx={{
              fontFamily: "poppins",
              fontSize: { xs: "14px", md: "16px" },
              fontWeight: 700,
              // mb: "15px",
              textAlign: "center",
              padding: 1,
            }}
          >
            Potential Salary Increase
          </Typography>
          <PotentialSalaryIncraeseChart
            value={data?.data?.potentialSalaryIncreasePercentage}
          />
        </Box>
      </Box>
    </React.Fragment>
  );

  // function to add subskills into main skill input

  function addSkill(skill) {
    const restSkills = skillList;
    setValue("primarySkill", [...restSkills, skill?.skillName]);
    const newSkils = [...autoValue, { name: skill?.skillName }];
    setAutoValue(newSkils);

    let data = {
      email: getEmail(),
      userLocation: location,
      deviceInfo: deviceInfo,
      primarySkill: newSkils,
    };

    onSubmit(data);
    // console.log("newSkils", newSkils);
    // console.log(skill, "skill123");
  }

  return (
    <ThemeProvider theme={relevenceTheme}>
      <React.Fragment>
        {/* Techrelevance Header section */}
        <Grid
          container
          display={"flex"}
          flexDirection={"row"}
          justifyContent={{
            xs: "center",
            sm: "space-between",
            md: "center",
            lg: "space-between",
          }}
          sx={{
            position: "sticky",
            top: 0,
            backgroundColor: "#fff",
            zIndex: 1,
            alignItems: "center",
          }}
        >
          {/* Header Section */}

          <Grid
            item
            xs={4.8}
            sm={5.5}
            order={{ sm: 1, md: 2 }}
            sx={{
              justifyContent: { sm: "flex-start", md: "flex-end" },
              display: "flex",
            }}
          >
            {/* {isLoggedIn ? (
              <Box>
                <Button variant="contained" endIcon={<LogoutIcon />} onClick={handleOpen}>
                  Logout
                </Button>
              </Box>
            ) : (
              ""
            )} */}
            {isSmallDevice ? (
              <IconButton onClick={toggleDrawer(true)}>
                <MenuIcon />
              </IconButton>
            ) : (
              <Grid item>
                {isLoggedIn && (
                  <Box
                    sx={{
                      pr: 3,
                    }}
                  >
                    <Button
                      sx={{
                        borderRadius: "55px",
                        textTransform: "capitalize",
                        backgroundImage:
                          "linear-gradient(to right, rgba(74,61,158,1), rgba(108,82,229,1) )",
                        border: "1px solid transparent",
                        transition: "all 0.3s linear",
                        "&:hover": {
                          border: "1px solid rgba(74,61,158,1)",
                          // background: "rgba(0, 158, 247, 0.10)",
                          background: "#FFF",
                          color: "rgba(108,82,229,1)",
                          transition: "all 0.3s linear",
                        },
                      }}
                      variant="contained"
                      endIcon={<LogoutIcon />}
                      onClick={handleOpen}
                    >
                      Logout
                    </Button>
                  </Box>
                )}
              </Grid>
            )}

            <Drawer
              anchor="left"
              open={drawerOpen}
              onClose={toggleDrawer(false)}
            >
              <Box
                sx={{ width: 250 }}
                role="presentation"
                onClick={toggleDrawer(false)}
                onKeyDown={toggleDrawer(false)}
              >
                <List>
                  <ListItem
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      sx={{
                        p: { xs: 1, md: 2, xl: 2 },
                        display: "flex",
                        justifyContent: "center",
                        maxWidth: { xs: "60px", md: "60px", lg: "80px" },
                      }}
                    >
                      <a href="/">
                        <img
                          src={"../../assets/images/TrueSelfy-logo.svg"}
                          alt="true-selfy"
                          loading="lazy"
                          style={{
                            maxWidth: `${isMobile ? "80px" : "80px"}`,
                            width: "100%",
                          }}
                        />
                      </a>
                    </Box>
                  </ListItem>
                  {isLoggedIn && (
                    <ListItem button onClick={handleOpen}>
                      <ListItemIcon>
                        <LogoutIcon />
                      </ListItemIcon>
                      <ListItemText primary="Logout" />
                    </ListItem>
                  )}
                </List>
              </Box>
            </Drawer>
          </Grid>
          <Grid item xs={7.2} sm={6.5} order={{ sm: 2, md: 1 }}>
            <Box
              sx={{
                p: { xs: 1, md: 2, xl: 2 },
                display: "flex",
                justifyContent: "center",
                maxWidth: { xs: "60px", md: "60px", lg: "80px" },
              }}
            >
              <a href="/">
                <img
                  src={"../../assets/images/TrueSelfy-logo.svg"}
                  alt="true-selfy"
                  loading="lazy"
                  style={{
                    maxWidth: `${isMobile ? "80px" : "80px"}`,
                    width: "100%",
                  }}
                />
              </a>
            </Box>
          </Grid>

          <Modal
            open={openLogout}
            onClose={handleClose}
            aria-labelledby="logout-modal-title"
            aria-describedby="logout-modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 300,
                bgcolor: "background.paper",
                border: "2px solid #000",
                boxShadow: 24,
                p: 4,
              }}
            >
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography id="logout-modal-title" variant="h6" component="h2">
                  Confirm Logout
                </Typography>
                <IconButton onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Grid>
              <Typography id="logout-modal-description" sx={{ mt: 2 }}>
                Are you sure you want to logout?
              </Typography>
              <Grid container justifyContent="space-between" sx={{ mt: 4 }}>
                <Button
                  sx={{ borderRadius: "55px" }}
                  onClick={handleLogout}
                  variant="contained"
                  color="primary"
                >
                  Yes
                </Button>
                <Button
                  sx={{ borderRadius: "55px" }}
                  onClick={handleClose}
                  variant="outlined"
                  color="secondary"
                >
                  No
                </Button>
              </Grid>
            </Box>
          </Modal>
        </Grid>

        {/* Techrelevance first Section */}
        <>
          {" "}
          {loading ? (
            <>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: {
                    xs: "68vh",
                    sm: "68vh",
                    md: "70vh",
                    lg: "78vh",
                  },
                  width: "100%",
                  overflow: "hidden",
                }}
                // position="fixed"
              >
                {/* <CircularProgress /> */}
                {isMobile ? (
                  <img
                    src={"../../../assets/images/loading-mobile.gif"}
                    alt="loading..."
                    loading="lazy"
                  />
                ) : (
                  <img
                    src={"../../../assets/images/loader3.gif"}
                    alt="loading..."
                    loading="lazy"
                  />
                )}
              </Box>
            </>
          ) : (
            <>
              {!isLoggedIn ? (
                <Box
                  sx={{
                    position: "relative",
                    backgroundColor: "rgba(35,28,79,0.4)",
                  }}
                >
                  {!videoLoaded && (
                    <img
                      src="../../assets/images/preload-image.png" // Path to your preload image
                      alt="Preload Image"
                      loading="lazy"
                      style={{
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        zIndex: -1, // Ensure preload image appears above video
                      }}
                    />
                  )}
                  <video
                    id="home-video"
                    autoPlay
                    loop
                    muted
                    onLoadedData={handleVideoLoaded} // Call handleVideoLoaded when video is loaded
                    style={{
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      zIndex: -1, // Ensure video appears behind preload image
                    }}
                  >
                    <source
                      src={`../../assets/images/backgroundVideo.mp4`}
                      type="video/mp4"
                    />
                  </video>
                  <Box
                    component="div"
                    sx={{
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      p: {
                        xs: "75px 20px",
                        sm: "245px 75px",
                        md: "280px 75px",
                        lg: "280px 75px",
                        xl: "175px 75px",
                      },
                      borderRadius: { xs: "20px", md: "66px" },
                      // color: "red",
                      overflow: "hidden",
                    }}
                  >
                    {/* <Grid>
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontWeight: 700,
                    fontSize: { xs: "24px", md: "52px" },
                    lineHeight: { xs: "40px", md: "62px" },
                    color: "#FFC218",
                    mb: "48px",
                    textAlign: { xs: "center", md: "center" },
                    // wordBreak: "break-word",
                  }}
                  variant="h1"
                >
                  Check your TechRelevance
                </Typography>
              </Grid> */}
                    {/* <Grid container display={"flex"} alignItems={"center"}> */}
                    <Grid
                      item
                      xs={12}
                      md={12}
                      order={{ xs: 2, sm: 2, md: 1 }}
                      // sx={{
                      //   py: { xs: 5, lg: "25vh" },
                      // }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Montserrat",
                          fontSize: {
                            xs: "26px",
                            md: "38px",
                            lg: "38px",
                            xl: "48px",
                          },
                          fontWeight: 600,
                          // lineHeight: "75px",
                          wordBreak: "break-word",
                          color: "#fff",
                          paddingBottom: isMobile ? "10px" : "0px",
                          textAlign: "center",
                        }}
                      >
                        Craft Your Career Journey with
                        {/* {poweredByChatGPT && "with AI!"} */}
                      </Typography>
                      <Typography
                        sx={{
                          color: "#fff",
                          textAlign: "center",
                          fontSize: {
                            xs: "38px",
                            md: "38px",
                            lg: "40px",
                            xl: "58px",
                          },
                          fontWeight: 700,
                          color: "transparent",
                          backgroundImage:
                            "linear-gradient(to right, #2BB344 25%, #FFC218, #E0011C, #0BA2FF)",
                          WebkitBackgroundClip: "text",
                          backgroundClip: "text",
                        }}
                      >
                        Artificial Intelligence!
                      </Typography>

                      <Box
                        sx={{
                          justifyContent: "center",
                          display: "flex",
                          width: "100%",
                        }}
                      >
                        {/* <Typography
                      sx={{
                        color: "#fff",
                        textAlign: "center",
                        display: { xs: "flex", sm: "none" },
                        flexDirection: "row",
                        justifyContent: "center",
                        pt: 2,
                      }}
                    >
                      What is TechRelevance 🤔 ?
                      <ClickAwayListener onClickAway={() => setRelevanceTooltip(false)}>
                        <Tooltip
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          onClose={() => setRelevanceTooltip(false)}
                          open={relevanceTooltip}
                          title={
                            <>
                              <Box>
                                <Typography sx={{ textAlign: "center" }}>
                                  TechRelevance helps you understand the lifespan of your skills in
                                  the ever-changing tech world. Stay ahead by knowing when to adapt.
                                </Typography>
                              </Box>
                            </>
                          }
                        >
                          <Box>
                            <InfoIcon
                              onClick={() => setRelevanceTooltip(true)}
                              sx={{
                                backgroundColor: "#000",
                                borderRadius: "100%",
                                color: "#ffc218",
                                ml: "5px",
                              }}
                            />
                          </Box>
                        </Tooltip>
                      </ClickAwayListener>
                    </Typography> */}
                        <Typography
                          sx={{
                            display: { xs: "flex", sm: "flex" },
                            fontFamily: "Montserrat",
                            fontSize: { xs: "18px", md: "24px", xl: "26px" },
                            fontWeight: 400,
                            lineHeight: "36px",
                            wordBreak: "break-word",
                            color: "#fff",
                            // color: { xs: "#000", md: "#fff" },
                            mb: "10px",
                            maxWidth: "1066px",
                            textAlign: "center",
                          }}
                        >
                          TechRelevance helps you understand the lifespan of
                          your skills in the ever-changing tech world. Stay
                          ahead by knowing when to adapt.
                        </Typography>
                      </Box>
                      <Box
                        py={2}
                        display={"flex"}
                        justifyContent={{ xs: "center", md: "center" }}
                      >
                        {!isLoggedIn ? (
                          <Button
                            variant="contained"
                            sx={{
                              // minWidth: { xs: "150px", md: "150px", lg: "150px", xl: "218px" },
                              borderRadius: "55px",
                              // background: `#FFF`,
                              color: "#fff",
                              fontSize: isMobile ? "16px" : "28px",
                              // backgroundColor: "#FFF !important",
                              backgroundImage:
                                "linear-gradient(to right, rgba(74,61,158,1), rgba(108,82,229,1) )",
                              textTransform: "capitalize",
                              border: "1px solid rgba(74,61,158,1)",
                              fontWeight: 600,
                              px: 8,
                              "&:hover": {
                                border: "1px solid rgba(74,61,158,1)",
                                // background: "rgba(0, 158, 247, 0.10)",
                                background: "#FFF",
                                color: "rgba(108,82,229,1)",
                                transition: "all 0.3s linear",
                              },
                            }}
                            onClick={() => {
                              if (!isLoggedIn) {
                                setOpen(true);
                              }
                              // console.log(isLoggedIn, "isLoggedIn");

                              // window.scrollTo({
                              //   top: document.documentElement.scrollHeight,
                              //   behavior: "smooth",
                              // });
                              // textFieldRef.current.focus();
                            }}
                          >
                            Start Now
                          </Button>
                        ) : (
                          ""
                        )}
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          pt: { xs: 0, xl: 2 },
                        }}
                      >
                        <Box
                          sx={{
                            maxWidth: { xs: "30px", sm: "42px", md: "52px" },
                          }}
                        >
                          <img
                            src="/assets/images/chat-gpt-logo.svg"
                            alt="powered-by-chat-gpt"
                            loading="lazy"
                            style={{
                              maxWidth: "52px",
                              width: "100%",
                            }}
                          />
                        </Box>
                        <Box sx={{ px: 1 }}>
                          <Typography sx={{ color: "#fff" }}>
                            Powered by ChatGPT
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    {/* </Grid> */}
                  </Box>
                </Box>
              ) : (
                ""
              )}

              {/* User Details Section */}
              {isLoggedIn ? (
                <Grid
                  container
                  alignItems={"flex-start"}
                  p={{
                    xs: "15px",
                    sm: "20px 50px",
                    md: "0px 50px",
                    lg: "30px 40px 0px 40px",
                    xl: "30px 70px 0px 70px",
                  }}
                  // spacing={{ xs: 4, md: 5, lg: 5, xl: 7 }}
                  justifyContent={"center"}
                >
                  <Grid
                    item
                    xs={12}
                    id={"enter-your-skills"}
                    sx={{ minHeight: "75vh" }}
                  >
                    <Typography
                      // color={"primary"}
                      sx={{
                        fontFamily: "poppins",
                        fontWeight: 700,
                        fontSize: { xs: "24px", sm: "30px", md: "45px" },
                        textAlign: "center",
                        // lineHeight: { xs: "40px", md: "62px" },
                        // color: "#fff",
                        mb: {
                          xs: "18px",
                          sm: "22px",
                          md: "18px",
                          lg: "37px",
                          xl: "37px",
                        },
                        mt: { xs: 0, sm: 0, md: "18px", lg: "0px" },
                        wordBreak: "break-word",
                        color: "#020D17",
                      }}
                    >
                      Enter Your Skills
                    </Typography>
                    <React.Fragment>
                      <FormProvider {...methods}>
                        <form
                          id="tech-relevance"
                          onSubmit={methods.handleSubmit(onSubmit)}
                        >
                          <TechRelevanceForm />
                          <Grid>
                            <Box py={4}>
                              {loadingButton ? (
                                <Grid item xs={12}>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "center",
                                      my: 5,
                                    }}
                                  >
                                    <CircularProgress />
                                  </Box>
                                </Grid>
                              ) : (
                                <Button
                                  id={"check-relevance-button"}
                                  type="submit"
                                  form="tech-relevance"
                                  // variant="contained"
                                  sx={{
                                    maxWidth: "333px",
                                    width: "100%",
                                    display: "block",
                                    m: "0px auto",
                                    color: "#FFF",
                                    P: "12px",
                                    fontSize: "24px",
                                    fontWeight: 600,
                                    borderRadius: "55px",
                                    textTransform: "capitalize",
                                    backgroundImage:
                                      "linear-gradient(to right, rgba(74,61,158,1), rgba(108,82,229,1) )",
                                    border: "1px solid transparent",
                                    transition: "all 0.3s linear",
                                    "&:hover": {
                                      border: "1px solid rgba(74,61,158,1)",
                                      // background: "rgba(0, 158, 247, 0.10)",
                                      background: "#FFF",
                                      color: "rgba(108,82,229,1)",
                                      transition: "all 0.3s linear",
                                    },
                                  }}
                                  loading={loadingButton}
                                  // disabled={skillLoading}
                                >
                                  {" "}
                                  Check TechRelevance
                                </Button>
                              )}
                            </Box>
                          </Grid>
                        </form>
                      </FormProvider>
                    </React.Fragment>
                  </Grid>
                </Grid>
              ) : (
                ""
              )}
              <SignInModal setOpen={setOpen} open={open} userData={userData} />
              {/* Tech relevance Section */}
              {relevanceResult?.length !== 0 &&
              relevanceResult !== undefined &&
              !loadingButton &&
              isLoggedIn ? (
                <>
                  <Joyride
                    steps={introSteps}
                    run={run}
                    showSkipButton
                    continuous
                    disableCloseOnEsc
                    disableOverlayClose
                  />
                  <Grid
                    container
                    p={{
                      xs: "15px",
                      sm: "20px 50px",
                      md: "0px 50px",
                      lg: "30px 40px 0px 40px",
                      xl: "30px 70px 0px 70px",
                    }}
                  >
                    <Grid item xs={12} id={"relevance-result"}>
                      <Typography
                        // color={"primary"}
                        sx={{
                          fontFamily: "poppins",
                          fontSize: { xs: "30px", md: "45px" },
                          textAlign: "center",
                          mb: "37px",
                          mt: { xs: "64px", sm: 10 },
                          fontWeight: 600,
                          wordBreak: "break-word",
                          color: "#020D17",
                        }}
                      >
                        {UserName.length !== 0
                          ? UserName === "Your"
                            ? `${getFirstWord(UserName)} TechRelevance`
                            : `${getFirstWord(UserName)}'s TechRelevance`
                          : ""}
                      </Typography>
                      <Grid container spacing={{ xs: 2, md: 5 }} rowSpacing={1}>
                        <Grid item xs={12} md={6}>
                          <Box
                            component="div"
                            // px={{ xs: 3, md: 10, lg: 10 }}
                            // py={{ xs: 3, md: 10, lg: 10 }}
                            sx={{
                              p: {
                                xs: "25px",
                                md: "30px",
                                lg: "30px",
                                xl: "60px",
                              },
                              border: "1px solid rgba(102,82,228,0.25)",
                              borderRadius: "39px",
                              boxShadow: "0px 0px 8px 0px #D9D9FF",
                              height: "100%",
                              boxSizing: "inherit",
                            }}
                          >
                            <Box
                              className="technical-validity-box"
                              sx={{
                                background: "#fff",
                              }}
                            >
                              <Box
                                sx={{
                                  maxWidth: { xs: "200px", xl: "270px" },
                                  width: "100%",
                                  height: "auto",
                                  m: "0px auto",
                                  mb: "36px",
                                }}
                              >
                                <img
                                  src="/assets/images/timer2.gif"
                                  style={{ width: "100%", height: "100%" }}
                                  alt="check-icon"
                                  loading="lazy"
                                />
                              </Box>
                              <Typography
                                color="primary"
                                sx={{
                                  fontFamily: "poppins",
                                  fontSize: "32px",
                                  fontWeight: 700,
                                  textAlign: "center",
                                  color: "#020D17",
                                }}
                              >
                                Technical validity until
                              </Typography>
                              <Typography
                                sx={{
                                  fontFamily: "poppins",
                                  fontSize: "65px",
                                  fontWeight: 700,
                                  textAlign: "center",
                                  color: "#6652E4",
                                }}
                              >
                                {
                                  getTopSkill(relevanceResult?.topPrimarySkill)
                                    .maxExpiryYear
                                }
                                <Typography variant="subtitle2">
                                  (approximately)
                                </Typography>
                              </Typography>
                              <Typography>
                                {
                                  getTopSkill(relevanceResult?.topPrimarySkill)
                                    ?.labelSuggestion
                                }
                              </Typography>
                            </Box>

                            {/* <Box
                        component="div"
                        sx={{
                          height: "1.5px",
                          background: "#000000",
                          maxWidth: "193px",
                          width: "100%",
                          m: "0px auto",
                          mt: "15px",
                        }}
                      ></Box> */}
                            <Grid
                              container
                              sx={{
                                // p: { xs: "10px, 10px", lg: "10px 45px", xl: "10px 45px" },
                                py: { xs: "20px", md: "10px", lg: "10px" },
                                px: { xs: "20px", md: "45px", xl: "45px" },
                                background: "rgba(43,179,68,15%)",
                                borderRadius: "15px",
                                mt: 4,
                              }}
                            >
                              <Grid item xs={12} lg={8} xl={7}>
                                <Box>
                                  <Typography
                                    sx={{
                                      textAlign: { xs: "center", lg: "left" },
                                      color: "#2BB344",
                                      fontFamily: "poppins",
                                      fontWeight: 700,
                                      fontSize: {
                                        xs: "20px",
                                        sm: "24px",
                                        lg: "24px",
                                        xl: "32px",
                                      },
                                    }}
                                  >
                                    Are you underpaid?
                                  </Typography>
                                  <Typography
                                    pb={2}
                                    sx={{
                                      pt: { xs: 2, sm: 1, md: 0 },
                                      textAlign: {
                                        xs: "center",
                                        sm: "center",
                                        md: "center",
                                        lg: "left",
                                      },
                                      fontSize: { xs: "18px", xl: "22px" },
                                    }}
                                  >
                                    Determine your potential salary by
                                    calculating TechWorth
                                  </Typography>
                                </Box>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                lg={4}
                                xl={5}
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <Box
                                  sx={{
                                    pb: { xs: 2, sm: 2, md: 2, lg: 0 },
                                  }}
                                >
                                  <Button
                                    sx={{
                                      // maxWidth: "370px",
                                      // width: isMobile ? "100%" : "100%",
                                      // display: "block",
                                      // m: "0px auto",
                                      color: "#FFF",
                                      px: "26px",
                                      fontSize: isMobile ? "16px" : "18px",
                                      fontWeight: 600,
                                      borderRadius: "55px",
                                      textAlign: "center",
                                      textTransform: "capitalize",
                                      background: "#2BB344",
                                      backgroundColor: "#2BB344",
                                      border: "1px solid #2BB344",

                                      "&:hover": {
                                        color: "#2BB344",
                                        background: "#fff",
                                        border: "1px solid #2BB344",
                                        transition: "all 0.3s linear",
                                      },
                                    }}
                                    size={"large"}
                                    component={"a"}
                                    href={"https://techworth.trueselfy.com/"}
                                  >
                                    Check TechWorth
                                  </Button>
                                </Box>
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Box
                            component="div"
                            sx={{
                              p: { xs: "25px", md: "35px" },
                              border: "2px solid rgba(102,82,228,0.25)",
                              borderRadius: "39px",
                              boxShadow: "0px 0px 8px 0px #D9D9FF",
                              height: "100%",
                              boxSizing: "inherit",
                            }}
                          >
                            <Grid
                              container
                              // direction="row"
                              alignItems={"center"}
                              sx={{ mb: isMobile ? "5px" : "0px" }}
                              justifyContent={{
                                xs: "center",
                                lg: "space-between",
                              }}
                            >
                              <Grid item xs={12} lg={8}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    gap: 2,
                                    alignItems: "center",
                                    justifyContent: {
                                      xs: "center",
                                      lg: "flex-start",
                                    },
                                  }}
                                >
                                  <Box
                                    sx={{
                                      // mr: { xs: 0, md: "20px" },
                                      maxWidth: { xs: "46px", md: "58px" },
                                      height: "100%",
                                      width: "100%",
                                    }}
                                  >
                                    <img
                                      src="/assets/images/skill-score-relevance-01.svg"
                                      width={"100%"}
                                      height="auto"
                                      alt="skill-score"
                                      loading="lazy"
                                    />
                                  </Box>

                                  <Typography
                                    sx={{
                                      fontFamily: "poppins",
                                      fontWeight: 700,
                                      fontSize: { xs: "28px", md: "32px" },
                                      letterSpacing: "0.5px",
                                      color: "#262655",
                                    }}
                                    variant="h5"
                                  >
                                    Skill score
                                  </Typography>
                                </Box>
                              </Grid>
                              {!isMobile && (
                                <Grid item xs={0} lg={4}>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Box>
                                      <Typography
                                        sx={{
                                          fontFamily: "poppins",
                                          fontSize: { sm: "18px", xl: "24px" },
                                          fontWeight: 700,
                                          textAlign: {
                                            xs: "center",
                                            md: "center",
                                            lg: "center",
                                          },
                                          wordBreak: "break-word",
                                          color: "#262655",
                                          mr: "5px",
                                        }}
                                      >
                                        Career Insights by ChatGPT
                                      </Typography>
                                    </Box>
                                    <Box>
                                      <img
                                        style={{ maxWidth: "40px" }}
                                        src="/assets/images/chat-gpt-logo.svg"
                                        alt="powered-by-chat-gpt"
                                        loading="lazy"
                                      />
                                    </Box>
                                  </Box>
                                </Grid>
                              )}
                              {autoValue?.length >= 5 && (
                                <Grid item xs={12}>
                                  <Box sx={{ py: 1 }}>
                                    <Alert
                                      severity="success"
                                      sx={{ alignItems: "center" }}
                                    >
                                      Displaying the top 5 skills based on the
                                      most recent validity year.
                                    </Alert>
                                  </Box>
                                </Grid>
                              )}
                            </Grid>

                            {relevanceResult?.topPrimarySkill?.map(
                              (item, index) => {
                                // if (item?.expiryYear > new Date().getFullYear() + 20) {
                                //   delete item?.expiryYear;
                                //   item.expiryYear = item?.estYear + 15;
                                // } else if (item?.expiryYear === null) {
                                //   delete item?.expiryYear;
                                //   item.expiryYear = new Date().getFullYear() + 2;
                                // }
                                return (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      gap: { xs: 2, lg: 2, xl: 15 },
                                      alignItems: {
                                        xs: "center",
                                        sm: "flex-end",
                                        md: "center",
                                        lg: "flex-end",
                                        xl: "flex-end",
                                      },
                                      justifyContent: "space-between",
                                      my: { xs: "20px", md: "30px" },
                                    }}
                                    flexDirection={{
                                      xs: "column",
                                      sm: "row",
                                      md: "column",
                                      lg: "row",
                                      xl: "row",
                                    }}
                                  >
                                    <Box
                                      flexGrow={1}
                                      sx={{
                                        width: "100%",
                                        background: "#fff",
                                        maxWidth: "700px",
                                      }}
                                      className={"skill-score"}
                                    >
                                      <Typography
                                        sx={{
                                          fontFamily: "poppins",
                                          fontSize: "22px",
                                          fontWeight: 700,
                                          display: "block",
                                          mb: "6px",
                                          mr: "20px",
                                          // wordBreak: "break-all",
                                        }}
                                      >
                                        {item?.name}
                                      </Typography>

                                      <Box
                                        sx={{
                                          display: "flex",
                                          gap: 2,
                                          alignItems: "center",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            fontSize: "18px",
                                            color: "#262655",
                                          }}
                                        >
                                          {item.type === "y"
                                            ? checkYear(item?.expiryYear).label
                                            : checkSkillType(item?.expiryYear)}
                                        </Typography>
                                        <Typography
                                          sx={{
                                            fontSize: "24px",
                                            fontWeight: 700,
                                          }}
                                          color={
                                            checkYear(item?.expiryYear)
                                              ?.percentageColor
                                          }
                                        >
                                          {item?.score <= 0
                                            ? "20"
                                            : item?.score > 100
                                            ? "80"
                                            : Math.round(item?.score)}
                                          %
                                        </Typography>
                                      </Box>
                                      <Box sx={{ width: "100%" }}>
                                        <LinearProgress
                                          sx={{
                                            height: "10px",
                                            borderRadius: "20px",
                                            "& .MuiLinearProgress-bar": {
                                              backgroundColor: checkYear(
                                                item?.expiryYear
                                              ).barPrimaryColor,
                                            },
                                            backgroundColor: checkYear(
                                              item?.expiryYear
                                            ).barSecondaryColor,
                                          }}
                                          color="error"
                                          variant="determinate"
                                          value={
                                            item?.score <= 0
                                              ? 20
                                              : item?.score > 100
                                              ? 80
                                              : Math.round(item?.score)
                                          }
                                        />
                                      </Box>
                                    </Box>
                                    {isMobile && (
                                      <Box
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <Box>
                                          <Typography
                                            sx={{
                                              fontFamily: "poppins",
                                              fontSize: "18px",
                                              fontWeight: 700,
                                              textAlign: {
                                                xs: "center",
                                                md: "center",
                                                lg: "center",
                                              },
                                              wordBreak: "break-word",
                                              color: "#262655",
                                              mr: "5px",
                                            }}
                                          >
                                            Career Insights by ChatGPT
                                          </Typography>
                                        </Box>
                                        <Box>
                                          <img
                                            style={{ maxWidth: "30px" }}
                                            src="/assets/images/chat-gpt-logo.svg"
                                            alt="powered-by-chat-gpt"
                                            loading="lazy"
                                          />
                                        </Box>
                                      </Box>
                                      // <>
                                      //   <Typography
                                      //     sx={{
                                      //       fontFamily: "poppins",
                                      //       // background: `linear-gradient(148deg, #0BA2FF -2.88%, #C2187F 87.25%, #100C3D 135.99%)`,
                                      //       // backgroundClip: "text",
                                      //       // WebkitBackgroundClip: "text",
                                      //       // WebkitTextFillColor: "transparent",
                                      //       fontSize: "18px",
                                      //       fontWeight: 700,
                                      //       color: "#262655",

                                      //       textAlign: "center",
                                      //     }}
                                      //   // className="typing-demo"
                                      //   >
                                      //     Career Insights by ChatGPT
                                      //   </Typography>
                                      // </>
                                    )}

                                    {poweredByChatGPT && (
                                      // <Box
                                      //   sx={{
                                      //     background: "#fff",
                                      //     display: "flex",
                                      //     flexDirection: "row",
                                      //   }}
                                      // >
                                      <Button
                                        sx={{
                                          width: "100%",
                                          maxWidth: "200px",
                                          color: "#FFF",
                                          alignItems: "center",
                                          justifyContent: "space-evenly",
                                          padding: "7px 12px",
                                          borderRadius: "27px",
                                          // background: `linear-gradient(148deg, #0BA2FF -2.88%, #C2187F 87.25%, #100C3D 135.99%)`,
                                          background:
                                            "linear-gradient(to right, rgba(74,61,158,1), rgba(108,82,229,1) )",
                                          transition: "all 0.3s linear",
                                          "&:hover": {
                                            border:
                                              "1px solid rgba(74,61,158,1)",
                                            // background: "rgba(0, 158, 247, 0.10)",
                                            background: "#FFF",
                                            color: "rgba(108,82,229,1)",
                                            transition: "all 0.3s linear",
                                          },
                                          "&:active,&:focus": {
                                            background: "#FFF",
                                            padding: "4px 10px",
                                            border: "2px solid #2F2876",
                                            color: "#2F2876 !important",
                                          },
                                        }}
                                        endIcon={
                                          <RocketLaunch
                                            sx={{
                                              fontSize: "16px",
                                            }}
                                          />
                                        }
                                        onClick={() =>
                                          handleSendClick(item?.name)
                                        }
                                        className={"get-insights"}
                                      >
                                        Get insights
                                      </Button>
                                      // </Box>
                                    )}
                                  </Box>
                                );
                              }
                            )}
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Box textAlign={"left"} pt={0.5}>
                            <Alert severity="info" sx={{ background: "#fff" }}>
                              Recommendations and potential salary increase
                              percentages provided are for informational
                              purposes only and should be used as suggestions,
                              not guarantees
                            </Alert>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                    {/* AI Boost powered Section */}
                    <Grid item xs={12}>
                      <Grid
                        container
                        spacing={5}
                        sx={{ alignItems: "flex-end" }}
                      >
                        {checkSkill !== "" &&
                        poweredByChatGPT &&
                        !chatgptError ? (
                          <Grid item xs={12} xl={12} id={"chat-gpt-result"}>
                            <Joyride
                              steps={InsightsSteps}
                              run={insightsStep}
                              continuous
                              showSkipButton
                              disableCloseOnEsc
                              disableOverlayClose
                            />

                            <>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  mb: "52px",
                                  mt: { xs: "45px", sm: "0px" },
                                }}
                              >
                                <Box sx={{ px: 1 }}>
                                  <Typography
                                    color={"primary"}
                                    sx={{
                                      fontFamily: "poppins",
                                      fontSize: { xs: "30px", md: "45px" },
                                      fontWeight: 600,
                                      textAlign: "center",
                                      wordBreak: "break-word",
                                      color: "#020D17",
                                    }}
                                  >
                                    Career Insights by ChatGPT
                                  </Typography>
                                </Box>

                                <Box>
                                  <img
                                    src="/assets/images/chat-gpt-logo.svg"
                                    alt="powered-by-chat-gpt"
                                    loading="lazy"
                                  />
                                </Box>
                              </Box>
                              <Box
                                component="div"
                                sx={{
                                  backgroundImage: `url('/assets/images/tech-relevence-chatgpt-bg-02.svg')`,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "cover",
                                  backgroundPosition: "center",
                                  p: { xs: "20px", md: "46px 44px" },
                                  borderRadius: { xs: "20px", md: "66px" },
                                  overflow: "hidden",
                                  border: "2px solid rgba(102,82,228,0.25)",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    mb: "43px",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      mr: {
                                        xs: "10px",
                                        sm: "10px",
                                        md: "20px",
                                      },
                                      width: { xs: "46px", md: "58px" },
                                      height: "100%",
                                    }}
                                  >
                                    <img
                                      src="/assets/images/bulb-img-01.svg"
                                      width="100%"
                                      height="100%"
                                      alt={"bulb-img"}
                                      loading="lazy"
                                    />
                                  </Box>
                                  <Typography
                                    sx={{
                                      fontFamily: "poppins",
                                      fontSize: { xs: "24px", md: "32px" },
                                      fontWeight: 700,
                                      color: "#262655",
                                    }}
                                  >
                                    {" "}
                                    {checkSkill}
                                  </Typography>
                                </Box>
                                <Grid container spacing={2}>
                                  <Grid item xs={12} sm={6} lg={3} xl={3}>
                                    <Box
                                      sx={{
                                        // maxWidth: "377px",
                                        // width: "100%",
                                        border: "1px solid #000088",
                                        // p: "30px",
                                        borderRadius: "39px",
                                        background: "#F3FBFF",
                                        height: "100%",
                                        boxSizing: "inherit",
                                      }}
                                      p={{ xs: 2, md: 3, lg: 2 }}
                                      className={"skill-popularity"}
                                    >
                                      <Typography
                                        color="primary"
                                        sx={{
                                          fontFamily: "poppins",
                                          textAlign: "center",
                                          fontSize: { xs: "24px", lg: "28px" },
                                          fontWeight: 700,
                                          // mb: `${isMobile ? "0px" : "48px"}`,
                                          color: "#000088",
                                        }}
                                      >
                                        Popularity
                                      </Typography>
                                      {chatGptButton ? (
                                        <Box
                                          sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            my: 5,
                                          }}
                                        >
                                          <CircularProgress />
                                        </Box>
                                      ) : (
                                        <Box pt={{ xs: 0, md: 0, lg: 6 }}>
                                          <PopularityChart
                                            value={
                                              parseInt(
                                                chatGPTData?.popularityRating
                                              ) * 10 || 10
                                            }
                                            color={"#4A3D9E"}
                                          />
                                        </Box>
                                      )}
                                    </Box>
                                  </Grid>
                                  <Grid item xs={12} sm={6} lg={3} xl={3}>
                                    <Box
                                      sx={{
                                        border: "1px solid #000088",
                                        borderRadius: "39px",
                                        background: "#F3FBFF",
                                        height: "100%",
                                        boxSizing: "inherit",
                                      }}
                                      className={"enterprise-adoption"}
                                      p={{ xs: 2, md: 3, lg: 2 }}
                                    >
                                      <Typography
                                        color="primary"
                                        sx={{
                                          fontFamily: "poppins",
                                          textAlign: "center",
                                          fontSize: { xs: "24px", lg: "28px" },
                                          fontWeight: 700,
                                          // mb: `${isMobile ? "0px" : "40px"}`,
                                          color: "#000088",
                                        }}
                                      >
                                        Enterprise Adoption
                                      </Typography>
                                      {chatGptButton ? (
                                        <Box
                                          sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            my: 5,
                                          }}
                                        >
                                          <CircularProgress />
                                        </Box>
                                      ) : (
                                        <Box
                                          sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                          }}
                                        >
                                          <IndustrialUsageChart
                                            value={parseInt(
                                              chatGPTData?.percentageUsage || 10
                                            )}
                                            color={"#4A3D9E"}
                                          />
                                        </Box>
                                      )}
                                    </Box>
                                  </Grid>

                                  <Grid item xs={12} sm={12} lg={6} xl={6}>
                                    <Box
                                      sx={{
                                        // maxWidth: "377px",
                                        // width: "100%",
                                        border: "1px solid #000088",
                                        // p: "30px 62px",
                                        borderRadius: "39px",
                                        background: "#F3FBFF",
                                        height: "100%",
                                        boxSizing: "inherit",
                                      }}
                                      className={"lear-earn-recommendations"}
                                      p={{ xs: 1, md: 3, lg: 2 }}
                                    >
                                      <Typography
                                        color="primary"
                                        sx={{
                                          fontFamily: "poppins",
                                          textAlign: "center",
                                          // fontSize: {
                                          //   xs: "21px",
                                          //   sm: "24px",
                                          //   md: "24px",
                                          //   lg: "32px",
                                          //   xl: "32px",
                                          // },
                                          fontSize: { xs: "24px", lg: "28px" },
                                          fontWeight: 700,
                                          color: "#000088",
                                          // mb: "10px",
                                        }}
                                      >
                                        Learn & Earn Recommendations
                                      </Typography>
                                      {chatGptButton ? (
                                        <Box
                                          sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            my: 5,
                                          }}
                                        >
                                          <CircularProgress />
                                        </Box>
                                      ) : (
                                        <Box
                                        // pl={{ xs: 0, md: 2, xl: 3 }}
                                        // mt={{ xs: 0, md: 3, lg: 3, xl: 3 }}
                                        >
                                          {chatGPTData?.latestTechnologies &&
                                          Array.isArray(
                                            chatGPTData?.latestTechnologies ||
                                              chatGPTData
                                          ) ? (
                                            <TableContainer
                                              sx={
                                                {
                                                  // overflowX: "hidden",
                                                }
                                              }
                                            >
                                              <Table
                                                sx={{
                                                  minWidth: 200,
                                                  overflowX: "hidden",
                                                }}
                                                aria-label="simple table"
                                              >
                                                <TableHead>
                                                  <TableRow>
                                                    <TableCell align="center">
                                                      <Typography
                                                        sx={{
                                                          fontFamily:
                                                            "Poppins, sans-serif",
                                                          fontSize: `${
                                                            isMobile
                                                              ? "12px"
                                                              : "22px"
                                                          }`,
                                                          fontWeight: 600,
                                                          textAlign: "center",
                                                        }}
                                                      >
                                                        Skill
                                                      </Typography>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                      <Typography
                                                        sx={{
                                                          fontFamily:
                                                            "Poppins, sans-serif",
                                                          fontSize: `${
                                                            isMobile
                                                              ? "12px"
                                                              : "22px"
                                                          }`,
                                                          fontWeight: 600,
                                                          textAlign: "center",
                                                        }}
                                                      >
                                                        Ease of Learning
                                                      </Typography>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                      <Typography
                                                        sx={{
                                                          fontFamily:
                                                            "Poppins, sans-serif",
                                                          fontSize: `${
                                                            isMobile
                                                              ? "12px"
                                                              : "22px"
                                                          }`,
                                                          fontWeight: 600,
                                                          textAlign: "center",
                                                        }}
                                                      >
                                                        Potential Salary
                                                        Increase
                                                      </Typography>
                                                    </TableCell>
                                                  </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                  {chatGPTData?.latestTechnologies?.map(
                                                    (item, index) => (
                                                      <TableRow key={index}>
                                                        <TableCell
                                                          component="th"
                                                          scope="row"
                                                          sx={{
                                                            display: "flex",
                                                            border: "none",
                                                          }}
                                                        >
                                                          <img
                                                            style={{
                                                              maxWidth: `${
                                                                isMobile
                                                                  ? "25px"
                                                                  : "30px"
                                                              }`,
                                                            }}
                                                            loading="lazy"
                                                            src="../../assets/images/fire-icon.svg"
                                                            alt="fire"
                                                          />
                                                          <Typography
                                                            sx={{
                                                              fontFamily:
                                                                "Poppins, sans-serif",
                                                              fontSize: `${
                                                                isMobile
                                                                  ? "12px"
                                                                  : "22px"
                                                              }`,
                                                              fontWeight: 400,
                                                              textAlign: "left",
                                                              paddingLeft:
                                                                "8px",
                                                            }}
                                                            component="a"
                                                            href="#"
                                                            onClick={() =>
                                                              addSkill(item)
                                                            }
                                                          >
                                                            {item?.skillName}
                                                          </Typography>
                                                        </TableCell>
                                                        <TableCell
                                                          align="center"
                                                          sx={{
                                                            border: "none",
                                                          }}
                                                        >
                                                          <Typography
                                                            sx={{
                                                              fontFamily:
                                                                "Poppins, sans-serif",
                                                              fontSize: `${
                                                                isMobile
                                                                  ? "12px"
                                                                  : "22px"
                                                              }`,
                                                              fontWeight: 400,
                                                              textAlign:
                                                                "center",
                                                            }}
                                                          >
                                                            {item?.easeOfLearning ||
                                                              (item
                                                                ?.easeOfLearning
                                                                ?.length === 0
                                                                ? easeOfLearning[
                                                                    randomIntFromInterval(
                                                                      0,
                                                                      2
                                                                    )
                                                                  ]
                                                                : easeOfLearning[
                                                                    randomIntFromInterval(
                                                                      0,
                                                                      2
                                                                    )
                                                                  ])}
                                                          </Typography>
                                                        </TableCell>
                                                        <TableCell
                                                          align="center"
                                                          sx={{
                                                            border: "none",
                                                          }}
                                                        >
                                                          <Typography
                                                            sx={{
                                                              fontFamily:
                                                                "Poppins, sans-serif",
                                                              fontSize: `${
                                                                isMobile
                                                                  ? "12px"
                                                                  : "22px"
                                                              }`,
                                                              fontWeight: 400,
                                                              textAlign:
                                                                "center",
                                                            }}
                                                          >
                                                            {item?.potentialSalaryIncreasePercentage ||
                                                              (item
                                                                ?.potentialSalaryIncreasePercentage
                                                                ?.length === 0
                                                                ? PotentialSalaryIncraese[
                                                                    randomIntFromInterval(
                                                                      0,
                                                                      7
                                                                    )
                                                                  ]
                                                                : PotentialSalaryIncraese[
                                                                    randomIntFromInterval(
                                                                      0,
                                                                      7
                                                                    )
                                                                  ])}
                                                            %
                                                          </Typography>
                                                        </TableCell>
                                                      </TableRow>
                                                    )
                                                  )}
                                                </TableBody>
                                              </Table>
                                            </TableContainer>
                                          ) : (
                                            <Box
                                              display={"flex"}
                                              justifyContent={"center"}
                                              flexDirection={"column"}
                                              textAlign={"center"}
                                            >
                                              <Box>
                                                <img
                                                  src="../../assets/images/empty-list.svg"
                                                  alt="data not found"
                                                  loading="lazy"
                                                  style={{
                                                    maxWidth: `${
                                                      isMobile ? "50%" : "200px"
                                                    }`,
                                                  }}
                                                />
                                              </Box>
                                              <Typography
                                                sx={{
                                                  fontFamily: "poppins",
                                                  textAlign: "center",
                                                  fontSize: {
                                                    xs: "18px",
                                                    lg: "21px",
                                                    xl: "24px",
                                                  },
                                                }}
                                              >
                                                Recommendations are not found
                                              </Typography>
                                              {initialCount <= 3 && (
                                                <Box
                                                  display={"flex"}
                                                  justifyContent={"center"}
                                                  alignItems={"center"}
                                                  sx={{
                                                    flexWrap: "wrap",
                                                    px: 2,
                                                    py: { xs: 1, lg: 0 },
                                                  }}
                                                >
                                                  <Typography pr={1}>
                                                    Retry with{" "}
                                                  </Typography>
                                                  <Button
                                                    sx={{
                                                      maxWidth: "200px",
                                                      width: "100%",
                                                      marginLeft: {
                                                        sm: "15px",
                                                      },
                                                      color: "#FFF",
                                                      alignItems: "center",
                                                      justifyContent:
                                                        "space-evenly",
                                                      padding: "7px 12px",
                                                      borderRadius: "27px",
                                                      border:
                                                        "1px solid rgba(74,61,158,1)",

                                                      // background: `linear-gradient(148deg, #0BA2FF -2.88%, #C2187F 87.25%, #100C3D 135.99%)`,
                                                      background:
                                                        "linear-gradient(to right, rgba(74,61,158,1), rgba(108,82,229,1) )",
                                                      transition:
                                                        "all 0.3s linear",
                                                      "&:hover": {
                                                        border:
                                                          "1px solid rgba(74,61,158,1)",
                                                        // background: "rgba(0, 158, 247, 0.10)",
                                                        background: "#FFF",
                                                        color:
                                                          "rgba(108,82,229,1)",
                                                        transition:
                                                          "all 0.3s linear",
                                                      },
                                                      "&:active,&:focus": {
                                                        background: "#FFF",
                                                        padding: "4px 10px",
                                                        border:
                                                          "2px solid #2F2876",
                                                        color:
                                                          "#2F2876 !important",
                                                      },
                                                    }}
                                                    endIcon={
                                                      <RocketLaunch
                                                        sx={{
                                                          fontSize: "16px",
                                                        }}
                                                      />
                                                    }
                                                    onClick={() =>
                                                      RetryGPT(checkSkill)
                                                    }
                                                  >
                                                    Get insights
                                                  </Button>
                                                </Box>
                                              )}
                                            </Box>
                                          )}
                                        </Box>
                                      )}
                                    </Box>
                                  </Grid>
                                </Grid>
                                {Hide_google_trends === false && (
                                  <Box
                                    sx={{
                                      // maxWidth: "377px",
                                      // width: "100%",
                                      border: "1px solid #000088",
                                      // p: "30px 62px",
                                      borderRadius: "39px",
                                      background: "#F3FBFF",
                                      height: "100%",
                                      boxSizing: "inherit",
                                      p: 2,
                                      mt: 3,
                                    }}
                                  >
                                    <GoogleTrendsEmbed
                                      keyword={checkSkill}
                                      geo=""
                                      time="today 5-y"
                                    />
                                  </Box>
                                )}
                              </Box>
                            </>
                          </Grid>
                        ) : (
                          ""
                        )}
                        {/* {checkSkill !== "" && poweredByChatGPT && !chatgptError && (
                    <Grid item xs={12} xl={3.1}>
                      <Box
                        sx={{
                          maxWidth: { xs: "300px", md: "400px", xl: "500px" },
                          width: "100%",
                          display: "block",
                          m: "0px auto",
                          backgroundImage: `url('/assets/images/result-interview-bg.png')`,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          borderRadius: "25px",
                          p: "8px 30px 42px",
                          textAlign: "center",
                        }}
                      >
                        <Box
                          sx={{
                            maxWidth: { xs: "170px", xl: "252px" },
                            width: "100%",
                            height: "auto",
                            m: "0px auto",
                            "& >img": {
                              width: "100%",
                              height: "100%",
                            },
                          }}
                        >
                          <img src="/assets/images/question-marks.png" alt="question-marks" />
                        </Box>
                        <Typography
                          sx={{
                            fontSize: { xs: "23px", lg: "32px" },
                            color: "#FFF",
                            fontWeight: 600,
                            mb: "35px",
                          }}
                        >
                          Get interview questions for preparation
                        </Typography>
                        <Button
                          sx={{
                            background: "#FFC218",
                            borderRadius: "100px",
                            maxWidth: "205px",
                            width: "100%",
                            fontSize: "24px",
                            border: "2px solid transparent",
                            textTransform: "unset",
                            "&:hover,&:active": {
                              background: "#FFF",
                              border: "2px solid #000",
                            },
                          }}
                          disabled={chatGptButton}
                          onClick={handleGetInterviewQuestions}
                        >
                          Get it now
                        </Button>
                      </Box>
                    </Grid>
                  )} */}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Toaster
                    position="top-center"
                    limit={1}
                    toastOptions={{
                      style: {
                        wordBreak: "break-all",
                        maxWidth: 800,
                      },
                    }}
                  >
                    {(t) => (
                      <ToastBar
                        toast={t}
                        style={{
                          // overflowWrap: "break-word",
                          wordBreak: "break-all",
                        }}
                      >
                        {({ icon, message }) => (
                          <>
                            {icon}
                            {message}
                            {t.type !== "loading"}
                          </>
                        )}
                      </ToastBar>
                    )}
                  </Toaster>
                </>
              ) : (
                ""
              )}
            </>
          )}
        </>
        <Box
          sx={{
            position: {
              lg: "unset",
              sm: "unset",
              xs: "unset",
              md: "unset",
              xl: "unset",
            },
            bottom: "0px",
            width: "100%",
          }}
        >
          <FooterComponent />
        </Box>
        {/* <Box
          sx={{
            position: "fixed",
            // transform: "translateZ(0px)",
            flexGrow: 1,
            alignItems: "center",
            display: "flex",
            zIndex: 9999,
            bottom: 15,
            right: "5%",
            gap: { xs: 0, md: 2, lg: 3 },
            justifyContent: "center",
          }}
        >
          <SpeedDial ariaLabel="SpeedDial basic example" icon={<ShareIcon />}>
            {actions.map((action) => (
              <SpeedDialAction
                key={action.name}
                icon={action.icon}
                tooltipTitle={action.name}
                onClick={action.onClick}
              />
            ))}
          </SpeedDial>
        </Box> */}
      </React.Fragment>
    </ThemeProvider>
  );
}

export default TechRelevanceV4;
