import * as React from "react";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Container from "@mui/material/Container";
import { Box } from "@mui/material";
import { useFeatureFlag } from "@harnessio/ff-react-client-sdk";
import { useLocation, useNavigate } from "react-router-dom";

function Copyright(props) {
  return (
    <Typography sx={{ fontSize: { xs: 16, md: 19 }, color: "#727272" }} {...props}>
      {"Copyright © "} {new Date().getFullYear()}{" "}
      <Link
        color="inherit"
        href={"https://vucaware.com/"}
        sx={{ cursor: "pointer" }}
        target="_blank"
      >
        Vucaware
      </Link>{" "}
      {/* {"."} */}
    </Typography>
  );
}

export default function FooterComponent(props) {
  const location = useLocation();
  const flagValue = useFeatureFlag("vucaware_logo_display");
  const navigate = useNavigate();

  const footers = [
    {
      title: "Privacy Policy",
      description: ["Privacy Policy", "Terms", "Modern Slavery Act", "Impression"],
      link: "https://vucaware.com/privacy-policy/",
    },
    {
      title: "Terms",
      description: [
        "Cool stuff",
        "Random feature",
        "Team feature",
        "Developer stuff",
        "Another one",
      ],
      link: "https://vucaware.com/terms-and-conditions/",
    },
    {
      title: "Shipping and Delivery Policy",
      description: ["Privacy Policy", "Terms", "Modern Slavery Act", "Impression"],
      link: "https://merchant.razorpay.com/policy/LrtLSHDrUk9pC7/shipping",
    },
  ];

  const social = [
    {
      title: "Facebook",
      icon: (
        <img
          src="../../../assets/images/fb-footer-icon.svg"
          style={{ width: "100%", height: "100%" }}
          alt="youtube"
          loading="lazy"
        />
      ),
      link: "https://www.facebook.com/TrueSelfy",
    },
    {
      title: "Linkdin",
      icon: (
        <img
          src="../../../assets/images/li-footer-icon.svg"
          style={{ width: "100%", height: "100%" }}
          alt="youtube"
          loading="lazy"
        />
      ),
      link: "https://www.linkedin.com/showcase/trueselfy/",
    },
  ];

  return (
    <Grid
      container
      spacing={{ xs: 2, md: 2 }}
      display={"flex"}
      flexDirection="row"
      justifyContent="space-between"
      // alignItems={"center"}
      p={{
        xs: "0px 20px",
        sm: "0px 50px",
        md: "0px 50px",
        lg: "0px 80px",
        xl: "0px 120px",
      }}
      sx={{ pb: { xs: 0, md: 3, lg: "20px !important" }, mt: { xs: 2, sm: 0 }, pt:{lg: "20px !important"} }}
    >
      <Grid
        item
        lg={6}
        md={5}
        xs={12}

      // sx={{ gap: { xs: "20px", md: "0px" } }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            gap: { xs: 2, md: 2, lg: 4 },
            justifyContent: { xs: "center", md: "center", lg: "center", xl: "flex-start" },
          }}
        >
          {flagValue ? (
            <>
              <Link href="https://vucaware.com" target="_blank" sx={{ textDecoration: "none" }}>
                <img
                  src="../../../assets/images/vucawarelogo.png"
                  alt="vucaware-logo"
                  height="100px"
                  loading="lazy"
                />
              </Link>
            </>
          ) : (
            <></>
          )}

          {footers.map((footer, index) => (
            <Link
              href={`${footer?.link}`}
              target="_blank"
              key={index}
              sx={{
                // textDecoration: "none",
                color: "#727272",
                fontSize: { xs: 16, md: 19 },
                "&:hover": {
                  color: "rgba(108,82,229,1)",
                },
              }}
            >
              {footer.title}
            </Link>
          ))}
          {
            <Link
              onClick={() => navigate("/contact-us")}
              display={location.pathname === "/contact-us" ? "none" : "inline-block"}
              sx={{
                // textDecoration: "none",
                color: "#727272",
                fontSize: { xs: 16, md: 19 },
                cursor: "pointer",
                "&:hover": {
                  color: "rgba(108,82,229,1)",
                },
              }}
            >
              {/* <Typography color="text.primary" pl={{ xs: 0, md: 0, lg: 7 }}> */}
              Contact us
              {/* </Typography> */}
            </Link>
          }
        </Box>
      </Grid>
      <Grid
        item
        // display={"flex"}
        // flexDirection={{ xs: "column", sm: "column", md: "row" }}
        // alignItems={"center"}
        // justifyContent={"center"}
        // spacing={{ xs: 2, md: 0 }}
        // sx={{ gap: "20px" }}
        sx={{ mb: { xs: "12px", sm: "12px", md: "0px", lg: "0px", xl: "0px" } }}
        lg={6}
        md={7}
        xs={12}
      >
        <Box
          sx={{
            display: "flex",
            flexWrap: { xs: "wrap", sm: "no-wrap" },
            gap: 2,
            justifyContent: { xs: "center", md: "center", lg: "flex-end", xl: "flex-end" },
            alignItems: "center",
          }}
          flexDirection={{ xs: "column", md: "column", lg: "row", xl: "row" }}
        >
          <Copyright />

          {/* <Grid
            item
            xs={12}
            lg={6}
            sm={6}
            md={6}
            justifyContent="flex-end"
            display={"flex"}
          > */}
          {/* </Grid> */}
          {/* <Grid
            xs={12}
            lg={6}
            sm={6}
            md={6}
            display={"flex"}
            flexDirection="row"
            alignItems={"center"}
            justifyContent="space-evenly"
            item
            gap={"20px"}
          > */}
          <Box
            sx={{
              display: "flex",
              gap: 2,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {social.map((item, index) => (
              <Link
                href={item?.link}
                target="_blank"
                sx={{
                  cursor: "pointer",
                  width: { xs: 30, md: 41 },
                  height: { xs: 30, md: 41 },
                  "&:hover": {
                    filter: "brightness(0.2)", // You can adjust the brightness for hover effect
                  },
                }}
                key={index}
              >
                {item.icon}
              </Link>
            ))}
          </Box>

          {/* </Grid> */}
        </Box>
      </Grid>
    </Grid>
  );
}
